import React from 'react'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share'

const config = {
    via: 't2hnd',
    size: 32
}

interface SocialProps {
    url: string
    title: string
    size?: number
    via?: string
}

export class Social extends React.Component<SocialProps, {}> {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return (
            <div className="social-buttons">
                <h2 className="title">SOCIAL</h2>
                <div className="buttons">
                    <FacebookShareButton url={this.props.url}>
                        <FacebookIcon size={this.props.size ? this.props.size : config.size} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={this.props.url} title={this.props.title} via={this.props.via ? this.props.via : config.via}>
                        <TwitterIcon size={this.props.size ? this.props.size : config.size} round />
                    </TwitterShareButton>
                </div>
            </div>
        )
    }
}